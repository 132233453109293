<template>
  <ProgressRoot v-bind="forwarded" class="ProgressRoot">
    <ProgressIndicator
      v-if="modelValue"
      class="ProgressIndicator"
      :style="`transform: translateX(-${100 - modelValue}%)`"
    />
  </ProgressRoot>
</template>

<script setup lang="ts">
import { type ProgressRootProps, type ProgressRootEmits, useForwardPropsEmits } from "radix-vue";

const props = defineProps<ProgressRootProps>();
const emits = defineEmits<ProgressRootEmits>();
const forwarded = useForwardPropsEmits(props, emits);
</script>

<style scoped lang="scss">
.ProgressRoot {
  position: relative;
  overflow: hidden;
  background: var(--gray-6);
  border-radius: 100px;
  width: 90%;
  height: 8px;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}

.ProgressIndicator {
  background-color: var(--blue);
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}
</style>
